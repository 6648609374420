'use strict';
const { miniCartReportingUrl } = require('./components/cart/common');

var getUrl = $('.cta-add-product').data('product');
var getIds = [];
var inCart = [];
var pageRefreshEndPoints = [
    'ReviewCartScheduling',
    'ReviewCartFinalStep',
    'TestPreparationStep',
    'ReviewCartTestPrep'
];

// eslint-disable-next-line
const addedClass = (inCart, event, pid) => {
    if ($('.cta-add-product').length) {
        if ($(event).hasClass('add-test')) {
            inCart.push(pid);
        }
        for (let index = 0; index < $('.cta-add-product').length; index++) {
            var pids = $('.cta-add-product').eq(index).data('pid').toString()
                .split(' ');
            if (inCart) {
                // eslint-disable-next-line
                var same = inCart.filter(el => pids.includes(el));
                if (JSON.stringify(same.sort()) === JSON.stringify(pids.sort())) {
                    $('.cta-add-product').eq(index).addClass('added');
                }
            }
        }
    }
};

const redirectTestPrepPages = (redirectionUrl, currentUrl) => {
    if (currentUrl.includes('ReviewCartFinalStep') || currentUrl.includes('ReviewCartTestPrep')) {
        window.location.href = redirectionUrl;
    }
};

const refreshPageSections = () => {
    let currentUrl = window.location.href;
    let isRefreshRequired = false;
    pageRefreshEndPoints.forEach(function (item) {
        if (currentUrl.includes(item)) {
            isRefreshRequired = true;
        }
    });

    if (isRefreshRequired) {
        $.ajax({
            url: currentUrl,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                if (data) {
                    var parsedHtml = $.parseHTML(data);
                    var $parsedHtml = $(parsedHtml);

                    if (currentUrl.includes('TestPreparationStep')) {
                        $('.cart-totals-size').replaceWith($parsedHtml.find('.cart-totals-size'));
                    } else {
                        $('.cart-totals-size').replaceWith($parsedHtml.find('.cart-totals-size'));
                        $('.cart-page-cards').replaceWith($parsedHtml.find('.cart-page-cards'));
                    }
                }
            },
            error: function () {
                console.warn('error refreshing page sections');
            }
        });
    }
};

$(document).ready(function () {
    /**
     * Check products in cart
     * @param {any} url - url of controller
     * @param {any} ids - ids to check
     * @returns {any} products in cart
     */
    function checkProductInCart(url, ids) {
        var products = ids;
        if (!products.length) {
            return false;
        }

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.items.length) {
                    var productsInCart = [];
                    data.items.forEach(item => {
                        productsInCart.push(item.masterProductID);
                    });
                    inCart = productsInCart;
                    addedClass(inCart);
                }
                $('.cta-add-product').removeClass('d-none');
            },
            error: function () {
                console.warn('no products');
            }
        });

        return new Promise((resolve) => {
            setTimeout(() => {
                // eslint-disable-next-line
                inCart = inCart;
                resolve();
            }, 500);
        });
    }

    $(document).on('click', '.cta-add-product', async function (e) {
        e.preventDefault();
        var pids = $(this).data('pid');
        var products = pids.toString();
        const addCartUrl = $(this).attr('data-add-cart-url');

        await checkProductInCart(getUrl, getIds);

        products = products.split(' ');
        products = products.filter(product => !inCart.includes(product));
        products.forEach(product => {
            const pid = product;
            const form = new FormData();
            let formData = {};

            formData = {
                pid: pid,
                quantity: 1
            };

            Object.keys(formData).forEach(key => {
                form.append(key, formData[key]);
            });

            if (addCartUrl) {
                fetch(addCartUrl, {
                    method: 'POST',
                    body: form
                })
                    .then(response => response.json())
                    .then(data => {
                        // eslint-disable-next-line
                        if (!data.error) {
                            // eslint-disable-next-line
                            $(this).addClass('added');
                            if ($(`.add-test[data-pid="${pid}"]`).length) {
                                $(`.add-test[data-pid="${pid}"]`).addClass('add d-none').siblings('.added').removeClass('d-none');
                            }
                            for (let gtmAddToCart of data.gtmAddToCart) {
                                window.dataLayer.push(gtmAddToCart);
                            }
                        }

                        $('.minicart').trigger('count:update', data);
                        $('body').trigger('product:afterAddToCart', data);
                        miniCartReportingUrl(data.reportingURL);
                        inCart.push(pid);

                        var redirectionURL = data.redirectURL;
                        let currentUrl = window.location.href;
                        redirectTestPrepPages(redirectionURL, currentUrl);
                        refreshPageSections();
                    }).catch(() => {
                        $.spinner().stop();
                    });
            }
        });
    });

    if ($('.cta-add-product').length) {
        for (let index = 0; index < $('.cta-add-product').length; index++) {
            var pids = $('.cta-add-product').eq(index).data('pid').toString()
                .split(' ');
            pids.forEach(pid => getIds.push(pid));
        }
        checkProductInCart(getUrl, getIds);
    }

    $('.cart-delete-confirmation-btn').on('click', function () {
        var mId = $(this).attr('data-mpid');
        inCart = inCart.filter(item => item !== mId);
    });
});

module.exports = {
    addedClass
};
