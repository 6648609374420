'use strict';

$('body').on('click', '.custom-control-accommodation', function () {
    var customCheck = $(this);
    var isChecked = customCheck.is(':checked');
    var url = $('.accommodation-check').val();
    var checkoutBtn = $('.checkout-btn');

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: JSON.stringify({
            productID: customCheck.attr('id'),
            isChecked: isChecked
        }),
        contentType: 'application/json',
        success: function (response) {
            if (!isChecked && response && response.containsNonSelfServeAccommodation === true) {
                checkoutBtn.addClass('disabled');
            }

            if (!isChecked && response && response.containsNonSelfServeAccommodation === false) {
                checkoutBtn.removeClass('disabled');
            }

            if (isChecked && response && response.containsNonSelfServeAccommodation === true) {
                checkoutBtn.removeClass('disabled');
            }
        },
        error: function () {
        }
    });
});
