'use strict';

var cart = require('../cart/cart');
const { updateAddedBtn } = require('./addToCartBtnStatus');

/**
 * updates header Minicart appending html
 * data to popover section.
 * @function
 */
function updateMinicart() {
    var url = $('.minicart').data('action-url');

    $('.minicart .popover').spinner().start();
    $.get(url, function (data) {
        $('.minicart .popover').empty();
        $('.minicart .popover').append(data);

        var isFromSRSummary = $('body').find('.score-recipients-main').length > 0;
        var newDataTarget = '#removeProductModalSummary';
        if (isFromSRSummary) {
            $('body').find('.container.cart .remove-product').attr('data-target', newDataTarget);
        }

        updateAddedBtn();
        $.spinner().stop();
    });
}

/**
 * Single responsability
 * function to display minicart section
 *
 * @function
 */
function showMiniCart() {
    $('.minicart .popover').addClass('show');
    $('nav .minicart-link').attr('aria-expanded', 'true');
}

/**
 * handles click or keypress on the
 * minicart icon
 * @function
 */
function handleMiniCartIconClick() {
    updateMinicart();
    showMiniCart();

    $('.minicart').addClass('active');
    $('.profile .logged, .pull-left.links, .menu-group').removeClass('active');
    $('.account-menu').removeClass('show');
    $('.icon--menu').removeClass('d-none').siblings('').addClass('d-none');
}

/**
 * handles tab focus inside
 * minicart popover : It will not let the tab focus come out of the popover until closed.
 * @function
 */
function miniCartFocusTrap() {
    const dialogContainerCloseButton = '.popover .cart button.close';
    const dialogContainerCheckoutButton = '.popover .cart a.checkout-btn';
    const dialogContainer = $('.popover');

    // Handle tab key press
    $(document).on('keydown', dialogContainerCloseButton, function (event) {
        if (event.key === 'Tab' && event.shiftKey) {
            if (dialogContainer.is(':visible')) {
                event.preventDefault();
                $(dialogContainerCheckoutButton).focus();
            }
        }

        if (event.key === 'Tab' && $(dialogContainerCheckoutButton).length === 0) {
            if (dialogContainer.is(':visible')) {
                event.preventDefault();
                $(dialogContainerCloseButton).focus();
            }
        }
    });

    $(document).on('keydown', dialogContainerCheckoutButton, function (event) {
        if (event.key === 'Tab' && !event.shiftKey) {
            if (dialogContainer.is(':visible')) {
                event.preventDefault();
                $(dialogContainerCloseButton).focus();
            }
        }
    });
}

module.exports = function () {
    cart();

    $(document).on('count:update', '.minicart', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart-quantity').removeClass('d-none').addClass('d-block');
            $('.minicart .minicart-quantity .minicart-quantity--total').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            updateMinicart();
            showMiniCart();
        }
    });

    $(document).on('touchstart click', '.minicart-link', function (event) {
        event.stopPropagation();
        handleMiniCartIconClick();
    });

    $(document).on('keyup', '.minicart-link', function (event) {
        event.stopPropagation();
        // keyCode for enter key is 13.
        if (event.keyCode === 13) {
            handleMiniCartIconClick();
        }
    });

    $(document).on('click', '.close', function (event) {
        event.stopPropagation();

        if ($('.minicart').is(':visible')) {
            $('.minicart .popover').removeClass('show');
            $('.minicart').removeClass('active');
            $('nav .minicart-link').attr('aria-expanded', 'false');
        }
        setTimeout(() => {
            event.preventDefault();
            $('nav .minicart-link').focus();
        }, 200);
    });

    setTimeout(() => {
        miniCartFocusTrap();
    }, 4000);
};
