'use strict';

const setRequiredAttributes = (field) => {
    field.closest('.form-group').classList.add('required');
    field.classList.add('required');
    field.setAttribute('required', '');
    field.setAttribute('aria-required', 'true');
};

const removeRequiredAttributes = (field) => {
    field.closest('.form-group').classList.remove('required');
    field.classList.remove('required');
    field.removeAttribute('required');
    field.removeAttribute('aria-required');
};

const attributeListener = (selectElement, callback) => {
    let observerCallback = function (mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes') {
                callback();
            }
        }
    };
    const observer = new MutationObserver(observerCallback);
    var config = { attributes: true, subtree: true };
    if (selectElement) {
        observer.observe(selectElement, config);
    }
};

const zipCode = () => {
    let countryField = document.querySelector('[name*="Profile_country"]');
    let postalCodeField = document.querySelector('[name*="Profile_postalCode"]');

    const zipCodeActions = () => {
        switch (countryField.value) {
            case 'USA':
            case 'CAN':
                setRequiredAttributes(postalCodeField);
                postalCodeField.minLength = 5;
                postalCodeField.pattern = postalCodeField.pattern.replace(1, 5);
                break;
            default:
                removeRequiredAttributes(postalCodeField);
                postalCodeField.minLength = 1;
                postalCodeField.pattern = postalCodeField.pattern.replace(5, 1);
                break;
        }
    };

    if (countryField !== null && postalCodeField !== null) {
        zipCodeActions();

        attributeListener(countryField.closest('.form-group').querySelector('.options-group'), zipCodeActions);
    }
};

const state = () => {
    let countryField = document.querySelector('[name*="Profile_country"]');
    let stateField = document.querySelector('[name*="Profile_state"]');

    const stateActions = () => {
        switch (countryField.value) {
            case 'USA':
            case 'CAN':
                setRequiredAttributes(stateField);
                break;
            default:
                removeRequiredAttributes(stateField);
                break;
        }
    };

    if (countryField !== null && stateField !== null) {
        stateActions();

        attributeListener(countryField.closest('.form-group').querySelector('.options-group'), stateActions);
    }
};

const checkStateValue = () => {
    let countryField = document.querySelector('[name*="Profile_country"]');
    let stateField = document.querySelector('[name*="Profile_state"]');

    const stateActions = () => {
        switch (countryField.value) {
            case 'USA':
            case 'CAN':
                if (stateField.value === stateField.querySelector('option') && stateField.querySelector('option').innerText) {
                    stateField.value = '';
                }
                break;
            default:
                break;
        }
    };

    if (countryField !== null && stateField !== null) {
        stateActions();
    }
};

const checkEmail = (valid, e) => {
    let form = e.target;
    let emailField = form.querySelector('#email') || form.querySelector('#registration-form-email');
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailField !== null) {
        let errorText = emailField.getAttribute('data-pattern-mismatch');
        valid = emailRegex.test(emailField.value);// eslint-disable-line no-param-reassign
        if (!valid) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            var parent = emailField.closest('.form-group');
            emailField.classList.add('is-invalid');
            parent.querySelector('.invalid-feedback').innerHTML = errorText;
        }
    }
    return valid;
};

module.exports = {
    zipCode,
    state,
    checkStateValue,
    checkEmail
};
