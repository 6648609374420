'use strict';

const { zipCode, state, checkStateValue, checkEmail } = require('./fieldsDynamicValidations');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    checkStateValue();
    valid = checkEmail(valid, event);
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $('.form-text').show();
    $('.btn').removeClass('promo-code-typing');
}

/**
 * getMessage
 * @returns {string} message validation
 */
function getMessage() {
    var validationMessage = this.validationMessage;
    $(this).addClass('is-invalid');
    if (this.validity?.patternMismatch && $(this).data('pattern-mismatch')) {
        validationMessage = $(this).data('pattern-mismatch');
    }
    if ((this.validity?.rangeOverflow || this.validity?.rangeUnderflow)
        && $(this).data('range-error')) {
        validationMessage = $(this).data('range-error');
    }
    if ((this.validity?.tooLong || this.validity?.tooShort)
        && $(this).data('range-error')) {
        validationMessage = $(this).data('range-error');
    }
    if (this.validity?.valueMissing && $(this).data('missing-error')) {
        validationMessage = $(this).data('missing-error');
    }
    if (this.validity === undefined) {
        validationMessage = $('[data-missing-error]')[0].dataset.missingError;
    }
    return validationMessage;
}

module.exports = {
    dynamicValidations: function () {
        zipCode();
        state();
    },

    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = getMessage.bind(this);
                $('.selected-option[data-placeholder]').each(function () {
                    var validationSelectMessage = getMessage.bind(this);
                    var selectedPlaceholder = $(this).attr('data-placeholder');
                    var selectedValue = $(this).text();
                    var customSelect = $(this).closest('.form-custom-select');
                    if (String(selectedValue.trim()) === String(selectedPlaceholder.trim())) {
                        if (!$(this).closest('#stateDiv').length) {
                            customSelect.addClass('is-invalid');
                        }
                        $(this).parents('.form-group')
                            .find('.invalid-feedback')
                            .text(validationSelectMessage);
                    }
                });
                $(this).siblings('.invalid-feedback')
                    .text(validationMessage)
                    .show();
                $(this).siblings('.form-text').hide();

                if ($(this).parents('form').find('input.is-invalid, select.is-invalid').eq(0)[0] === $(this)[0]) {
                    let $targetElement = $(this);
                    if ($targetElement.is('select')) {
                        $targetElement = $targetElement.parents('.input-group');
                    }

                    const targetLabelHeight = $targetElement.siblings('.form-control-label, .custom-control-label').outerHeight(true) || 0;
                    const headerHeight = $('.header-sticky').outerHeight() + ($('.cart-page-head').outerHeight() || 0);
                    const topPosition = $targetElement.offset().top - headerHeight - targetLabelHeight;

                    $('html').animate({
                        scrollTop: topPosition
                    }, 200);
                }
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
            $('.promo-code-btn').addClass('promo-code-btn-invalid');
        });
    },

    promoCodeSubmit: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });

        $('body').on('keyup', '.coupon-code-field ', function () {
            $('.promo-code-btn').addClass('promo-code-typing');
        });

        $('body').on('click', '.delete-coupon-confirmation-btn ', function () {
            $('.promo-code-btn').removeClass('promo-code-typing');
            $('.promo-code-btn').removeClass('promo-code-invalid');
            $('.promo-code-btn').removeClass('promo-code-valid');
        });
    },

    trimNameFields: function () {
        $('input[name*="_firstname"], input[name*="_lastname"]').on('blur', function () {
            $(this).val((i, currentVal) => currentVal.trim());
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};
