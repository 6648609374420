'use strict';

/**
 * checks the window size in order to show/hide footer collapse menus
 */
function checkFooterCollapse() {
    const mql = window.matchMedia('(min-width: 992px)');
    const $footerCollapseEl = $('footer .collapse');
    const $footerCollapseTitle = $('footer .footer-title');

    if (mql.matches) {
        $footerCollapseEl.collapse('show');
        $footerCollapseTitle.removeAttr('tabindex');
    }

    mql.onchange = (e) => {
        if (e.matches) {
            $footerCollapseEl.collapse('show');
            $footerCollapseTitle.removeAttr('tabindex');
        } else {
            $footerCollapseEl.collapse('hide');
            $footerCollapseTitle.attr('tabindex', '0');
        }
    };
}

module.exports = function () {
    checkFooterCollapse();

    $('footer a').each(function () {
        if ($(this).text().trim()) {
            $(this).attr('data-title', $(this).text());
        }
    });

    //  Removing onetrust default styling in footer cookies
    $('#ot-sdk-btn').addClass('sdk-override');
};
