'use strict';

const base = require('base/components/focus');

base.trapFocus = function (focusParams) {
    if (focusParams.event.key !== 'Tab') {
        return;
    }

    const $container = $(focusParams.containerSelector);
    const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex], [contenteditable]';

    const focusableItems = $container.find(focusableElementsString).filter(':visible');

    const focusedItem = $(':focus');
    const focusedItemIndex = focusableItems.index(focusedItem);

    if (focusParams.event.shiftKey) {
        //  back tab
        //  if focused on first item and user preses back-tab, go to the last focusable item
        if (focusedItemIndex === 0) {
            focusableItems.eq(focusableItems.length - 1).trigger('focus');
            focusParams.event.preventDefault();
        }
    } else if (focusedItemIndex === focusableItems.length - 1) {
        //  forward tab
        //  if focused on the last item and user preses tab, go to the first focusable item
        focusableItems.eq(0).trigger('focus');
        focusParams.event.preventDefault();
    }
};

module.exports = base;
