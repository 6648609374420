'use strict';

const toggleMenuDropdown = function ($currentMenuItem, toOpen) {
    if (!$currentMenuItem.children('.dropdown-toggle').length) return;

    $currentMenuItem.children('.dropdown-toggle').attr('aria-expanded', toOpen);

    if (toOpen) {
        $currentMenuItem.addClass('show');
        $currentMenuItem.children('.dropdown-menu').addClass('show');
    } else {
        $currentMenuItem.removeClass('show');
        $currentMenuItem.children('.dropdown-menu').removeClass('show');
    }
};

module.exports = function () {
    let isTouch = false;
    $('.dropdown:not(.disabled), .section-navigation, .navbar-header .dropdown-mobile')
        .find('a.dropdown-item').on('mouseenter keydown', function (e) {
            if (e.code === 'ArrowUp') {
                e.preventDefault();
                $(this).prev().focus();
            }
            if (e.code === 'ArrowDown') {
                e.preventDefault();
                $(this).next().focus();
            }
        });

    $('.dropdown:not(.disabled), .section-navigation, .navbar-header .dropdown-mobile').find('[data-toggle="dropdown"]')
        .on('touchstart', function () {
            isTouch = true;
        })
        .on('click', function (e) {
            e.preventDefault();

            // toggle dropdown on mobile
            if (!isTouch) return;

            const $currentMenuItem = $(this).parent();
            const $otherMenuItems = $currentMenuItem.siblings();
            if ($currentMenuItem.hasClass('show')) {
                toggleMenuDropdown($currentMenuItem, false);
            } else {
                toggleMenuDropdown($currentMenuItem, true);
                toggleMenuDropdown($otherMenuItems, false);
            }
        })
        .on('mouseenter keydown', function (e) {
            if (isTouch) return;

            const $currentMenuItem = $(this).parent();

            if ($currentMenuItem.hasClass('show')) {
                if (e.type === 'keydown' && (e.key === 'Enter' || e.key === 'Escape' || e.code === 'Space')) { // close dropdown by keypress
                    e.preventDefault();
                    toggleMenuDropdown($currentMenuItem, false);
                }
            } else if (e.type === 'mouseenter' || (e.type === 'keydown' && (e.key === 'Enter' || e.code === 'Space' || e.code === 'ArrowDown' || e.code === 'ArrowUp'))) { //  open dropdown on desktop
                const $otherMenuItems = $currentMenuItem.siblings();
                e.preventDefault();
                toggleMenuDropdown($currentMenuItem, true);
                toggleMenuDropdown($otherMenuItems, false);
            }
            if ($currentMenuItem.hasClass('show')) {
                if (e.type === 'keydown' && (e.code === 'ArrowDown' || e.code === 'ArrowUp')) { // close dropdown by keypress
                    var links = $(this).closest('.dropdown').find('a.dropdown-item');
                    if (e.code === 'ArrowUp') {
                        e.preventDefault();
                        links.eq(links.index(this) + links.length).focus();
                    }
                    if (e.code === 'ArrowDown') {
                        e.preventDefault();
                        links.eq(links.index(this) + 1).focus();
                    }
                }
            }
        })
        .parent()
        .on('mouseleave', function () { // close dropdown on desktop
            toggleMenuDropdown($(this), false);
        });

    $('.navbar-header .logged').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active').siblings('.account-menu').toggleClass('show');
        $('.icon--menu').removeClass('d-none').siblings().addClass('d-none');
        $('.navbar-header .links').removeClass('active');

        if (!$('.menu-mobile .icon--menu.d-none').length && !$('.profile .logged.active').length) {
            $('.navbar-header .menu-group').removeClass('active');
        } else {
            $('.navbar-header .menu-group').addClass('active');
        }

        if ($('.minicart').is(':visible')) {
            $('.minicart .popover').removeClass('show');
            $('.minicart').removeClass('active');
        }
    });

    $('.navbar-header .menu-mobile').on('click', function (e) {
        e.preventDefault();
        $('.navbar-header .links').toggleClass('active');
        $('.menu-mobile .icon').toggleClass('d-none');
        $('.logged, .account-menu').removeClass('show active');
        $('.dropdown-mobile, .dropdown-menu').removeClass('show');

        if (!$('.menu-mobile .icon--menu.d-none').length && !$('.profile .logged.active').length) {
            $('.navbar-header .menu-group').removeClass('active');
        } else {
            $('.navbar-header .menu-group').addClass('active');
        }

        if ($('.minicart').is(':visible')) {
            $('.minicart .popover').removeClass('show');
            $('.minicart').removeClass('active');
        }
    });

    if (localStorage.getItem('closePModal') && !localStorage.getItem('promoSended')) {
        $('.button__promotion').removeClass('d-none');
    }

    if (!localStorage.getItem('closePModal') && !localStorage.getItem('promoSended')) {
        setTimeout(() => {
            $('.modal__promotion').addClass('d-block');
        }, 3000);
    }

    /**
     * Event handler store link from data option
     * Attr to cta button
     * @param {any} elementSelector - element to validate.
     * @param {Event} callback - callback event.
     */
    function elementValidate(elementSelector, callback) {
        var actualElement = $(elementSelector).length > 0;
        var checkedValidation = false;

        if (actualElement && !checkedValidation) {
            checkedValidation = true;
            callback();
        } else if (!checkedValidation) {
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function () {
                    if ($(elementSelector).length > 0 && !checkedValidation) {
                        checkedValidation = true;
                        observer.disconnect();
                        callback();
                    }
                });
            });

            observer.observe(document.body, { childList: true, subtree: true });
        }
    }

    elementValidate('.mktoFormRow', function () {
        $('.mktoEmailField').attr('placeholder', 'Enter your email address');
        $('.mktoButtonRow').prepend('<span class="dimiss">No, Thanks</span>');
        $('.mktoButton').html('Get Discount').attr('disabled', true);
    });

    $('#mktoForm_4').on('click', '.mktoField', function () {
        if ($(this).is(':checked')) {
            $('.mktoButton').removeAttr('disabled');
        } else {
            $('.mktoButton').attr('disabled', true);
        }
    });

    $('.modal__promotion').on('click', '.close, .dimiss', function (e) {
        e.preventDefault();
        localStorage.setItem('closePModal', true);
        $('.modal__promotion').removeClass('d-block');
        $('.button__promotion').removeClass('d-none');
    });

    $('.button__promotion--open').on('click', function () {
        console.log('as');
        $('.modal__promotion').addClass('d-block');
    });

    $('.button__promotion .icon').on('click', function () {
        $('.button__promotion').addClass('d-none');
    });
};
