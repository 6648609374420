'use strict';

var focusHelper = require('../components/focus');

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    var trackingConsentData = $('.tracking-consent');
    if (!trackingConsentData.data('caonline')) {
        return;
    }

    var urlContent = trackingConsentData.data('url');
    var urlAccept = trackingConsentData.data('accept');
    var urlReject = trackingConsentData.data('reject');
    var textYes = trackingConsentData.data('accepttext');
    var textNo = trackingConsentData.data('rejecttext');
    var textHeader = trackingConsentData.data('heading');
    var tokenName = trackingConsentData.data('tokenname');
    var token = trackingConsentData.data('token');

    var htmlString = `
        <!-- Modal -->
        <div class="modal modal__consent" id="consent-tracking" aria-modal="true" role="dialog" aria-labelledby="modal-consent-title" aria-describedby="modal-desc">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header"  id="modal-consent-title">
                        ${textHeader}
                    </div>
                    <div class="modal-body" id="modal-desc"></div>
                    <div class="modal-footer">
                        <div class="button-wrapper">
                            <button class="affirm btn btn-primary" data-url="${urlAccept}">
                                ${textYes}
                            </button>
                            <button class="decline btn btn-secondary" data-url="${urlReject}">
                                ${textNo}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
    $.spinner().start();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.modal-body').html(response);
            $('#consent-tracking').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking .button-wrapper button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var data = {};
        data[tokenName] = token;
        $.ajax({
            url: url,
            type: 'post',
            data: data,
            dataType: 'json',
            success: function (response) {
                // Only hide modal if the operation is successful - don't want to give a false impression
                if (response.success) {
                    $('#consent-tracking').modal('hide');
                }
            },
            error: function (err) {
                // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });
}

module.exports = function () {
    let $prevFocusedElement;
    $('.show-cookies-consent').on('click', (e) => {
        e.preventDefault();
        $prevFocusedElement = $(':focus');
        showConsentModal();
    });

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            $prevFocusedElement = $(':focus');
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking a').eq(0).trigger('focus');
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
        $prevFocusedElement.trigger('focus');
        $('#consent-tracking').remove();
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        const focusParams = {
            event: e,
            containerSelector: '#consent-tracking'
        };
        focusHelper.trapFocus(focusParams);
    });
};

