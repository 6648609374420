'use strict';

const updateParentClass = () => {
    // Update children 'disabled' state based on parent's 'added' buttons
    $('.course.parent').each(function () {
        var parent = $(this);
        var parentButtonAdded = parent.find('.add-test.btn.added');
        var dataCourseValue = parent.data('course');
        var children = $('.course.child[data-course="' + dataCourseValue + '"]');

        // If the parent's 'added' button does not have 'd-none', disable all children
        if (!parentButtonAdded.hasClass('d-none')) {
            children.addClass('disabled');
        } else {
            // If it does, enable all children
            children.removeClass('disabled');
        }
    });

    // Update parent 'disabled' state based on children's 'added' buttons
    $('.course.child').each(function () {
        var child = $(this);
        var dataCourseValue = child.data('course');
        var parent = $('.course.parent[data-course="' + dataCourseValue + '"]');
        var children = $('.course.child[data-course="' + dataCourseValue + '"]');
        var allAddedButtonsHidden = children.find('.add-test.btn.added').length === children.find('.add-test.btn.added.d-none').length;

        // If all children's 'added' buttons have 'd-none', enable parent
        if (allAddedButtonsHidden) {
            parent.removeClass('disabled');
        } else {
            // If any child's 'added' button does not have 'd-none', disable parent
            parent.addClass('disabled');
        }
    });
};

module.exports = {
    updateParentClass
};
