'use strict';

const checkStatusAddBtn = (pidsInCart = [], addToCartBtn) => {
    const pidInAddToCartBtn = addToCartBtn.dataset.pid;
    const addedLabel = addToCartBtn.dataset.added;
    const addToCartLabel = addToCartBtn.dataset.addtocart;
    const addToCartLabelElement = addToCartBtn.querySelector('.label');
    const icon = document.createElement('span');
    icon.className = 'icon--add';

    if (pidsInCart.includes(pidInAddToCartBtn)) {
        addToCartBtn.setAttribute('disabled', 'true');
        addToCartBtn.classList.add('added');
        addToCartLabelElement !== null ?
            (addToCartLabelElement.innerText = addedLabel) :
            (addToCartBtn.innerText = addedLabel); // eslint-disable-line no-param-reassign
    } else {
        addToCartBtn.removeAttribute('disabled');
        addToCartBtn.classList.remove('added');
        addToCartLabelElement !== null ?
            (addToCartLabelElement.innerText = addToCartLabel) :
            (addToCartBtn.innerText = addToCartLabel); // eslint-disable-line no-param-reassign
        addToCartBtn.dataset.recommendation ? addToCartBtn.appendChild(icon) : ''; // eslint-disable-line no-unused-expressions
    }
};

const updateAddedBtn = () => {
    const itemsInCart = document.querySelectorAll('.miniCart-container .remove-btn[data-mpid]');
    const bonusItemsInCart = document.querySelectorAll('.miniCart-container .bonus-item[data-mpid]');
    const pidsInCart = [...itemsInCart].map(item => item.dataset.mpid) ?? [];
    const bonusPidsInCart = [...bonusItemsInCart].map(item => item.dataset.mpid) ?? [];

    const comparePidsCart = pidsInCart.concat(bonusPidsInCart);

    const pdpAddToCart = document.querySelectorAll('.add-to-cart');
    const pdpAddToCartRecommendation = document.querySelectorAll('.add-to-cart-recommendation');

    [...pdpAddToCart].forEach(addToCartBtn => checkStatusAddBtn(comparePidsCart, addToCartBtn));
    [...pdpAddToCartRecommendation].forEach(addToCartBtn => checkStatusAddBtn(comparePidsCart, addToCartBtn));
};

module.exports = {
    updateAddedBtn
};
