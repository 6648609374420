'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');
const { updateParentClass } = require('../components/updateCombinedTests');
const { updateAddedBtn } = require('../components/addToCartBtnStatus');
var name = '';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity--total').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);

    var onlineServiceAmount = $('.online-service-total');

    if (onlineServiceAmount.length) {
        onlineServiceAmount.empty().append(data.totals.onlineServiceFeeTotal);
    }

    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity--total').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    const discountSavingsAlert = document.querySelector('.discount-savings-alert');
    if ('couponSavings' in data && data.couponSavings.value > 0) {
        if (discountSavingsAlert !== null) {
            discountSavingsAlert.querySelector('.details').innerText = data.couponSavings.formatted;
            discountSavingsAlert.style.display = 'block';
        }
    } else if (discountSavingsAlert !== null) {
        discountSavingsAlert.removeAttribute('style');
        discountSavingsAlert.classList.remove('show');
    }

    const dataProducts = data.items;
    for (let productItem of dataProducts) {
        let cartItemDOM = document.querySelector('.line-product.uuid-' + productItem.UUID);
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!cartItemDOM) {
            let priceElement = cartItemDOM.querySelector('.text-right');
            let isDifferentPrice = productItem.priceTotal.price !== productItem.priceTotal.nonAdjustedPrice && productItem.priceTotal.nonAdjustedPrice !== undefined;
            let parentItem = cartItemDOM.parentNode;
            let removeBtn = '';

            isDifferentPrice ?
                (priceElement.innerHTML = `<span class="strike-through">${productItem.priceTotal.nonAdjustedPrice}</span><br/>${productItem.priceTotal.price}`) :
                (priceElement.innerHTML = productItem.priceTotal.price);

            if (productItem.priceTotal.price && productItem.priceTotal.price !== '$0.0') {
                removeBtn = `<a href="" class="btn-remove remove-product AT-review-remove-button" data-toggle="modal"
                data-target=".cart.cart-page #removeProductModal" data-pid="${productItem.id}"
                data-name="${productItem.productName}"
                data-action="${data.actionUrls.removeProductLineItemUrl}"
                data-uuid="${productItem.UUID}"
                >Remove</a>`;
            }

            if (!parentItem.querySelector('.btn-remove')) {
                parentItem.insertAdjacentHTML('beforeend', removeBtn);
            }
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} masterID - master product id
 * @param {string} offerMessage - offer delete message
 */
function confirmDelete(actionUrl, productID, productName, uuid, masterID, offerMessage) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');
    var removeProductMessage = $('.remove-product-message');

    $deleteConfirmBtn.attr('data-mpid', masterID);
    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    if (offerMessage) {
        $productToRemoveSpan.empty().append('');
        removeProductMessage.empty().append(offerMessage);
        $deleteConfirmBtn.data('offer', true);
    } else {
        removeProductMessage.empty().append(removeProductMessage.data('message'));
        $productToRemoveSpan.empty().append(productName);
        $deleteConfirmBtn.data('offer', false);
    }
}

/**
 * Updates minicart data
 * @param {Object} data - AJAX response from the server
 */
function updateMinicart() {
    var url = $('.minicart').data('action-url');
    $('.minicart .popover').spinner().stop();
    $('.minicart .popover').spinner().start();
    $.get(url, function (data) {
        $('.minicart .popover').empty();
        $('.minicart .popover').append(data);

        updateAddedBtn();
        $.spinner().stop();
    });
}

const updateUIAfterRemove = (data, uuid) => {
    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
        }
    }
    $('.uuid-' + uuid).remove();
    $('[data-uuid="' + uuid + '"]').remove();
    if (!data.basket.hasBonusProduct) {
        $('.bonus-product').remove();
    }
    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
    updateCartTotals(data.basket);
    updateApproachingDiscounts(data.basket.approachingDiscounts);
    $('body').trigger('setShippingMethodSelection', data.basket);
    validateBasket(data.basket);

    if (!data.basket.basketHavePLPBonus && document.querySelector('.bonus-promotion-card')) {
        document.querySelector('.bonus-promotion-card').classList.add('d-none');
    }
};

/**
 * Analyse if Recommended Product data is there or not
 * @param {Object} carousel - carousel node
 * @returns {boolean} returns the boolean against carousel item is present or not
 */
function isRecommendedProductExists(carousel) {
    /* eslint-disable consistent-return */
    let items = carousel?.find('.carousel-item');
    // Iterate over each item in the collection
    items?.each(function (item) {
        if ($(item).children().length > 0) {
            return true;
        }
    });
    return false;
}

/**
 * updateProductItems
 * @param {Object} productItems product cart items
 */
function updateProductItems(productItems = []) {
    let pageIDItems = [...document.querySelectorAll('.cart-page-cards .card.product-info[data-id]')].map(e => e.getAttribute('data-id'));
    let cartIDItems = productItems.map(e => e.id);
    let nonCartItems = pageIDItems.filter(item => !cartIDItems.includes(item));

    if (nonCartItems.length) {
        nonCartItems.forEach(pageItem => {
            let uuidItem = [...document.querySelector('[data-id="' + pageItem + '"]').classList].find(className => /uuid/g.test(className));
            [...document.querySelectorAll('.' + uuidItem)].forEach(e => e.remove());
        });
    }
}

/**
 * updateProductItems
 * @param {Object} recoItems recommendations cart items
 */
function updateRelatedItems(recoItems = []) {
    recoItems.forEach(pageItem => {
        if (pageItem.available && $('.addCartButtonResults[data-pid="' + pageItem.id + '"]').hasClass('adding')) {
            $('.addCartButtonResults[data-pid="' + pageItem.id + '"]').removeClass('adding').html('Add to Cart <span class="icon icon--add"></span>');
        }
    });
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var masterID = $(this).data('mpid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var showOfferMessage = $(this).data('show-offer-message');
        var offerMessage = showOfferMessage ? $(this).data('offer-message') : '';

        var isClickFromMiniCart = $(this).parents('.miniCart-container').length > 0;

        if (isClickFromMiniCart) {
            $('.modal__confirm-deletion').addClass('is-from-minicart');
            confirmDelete(actionUrl, productID, productName, uuid, masterID, offerMessage);
            $('.cart-delete-confirmation-btn').attr('data-pid', productID);
        }
        confirmDelete(actionUrl, productID, productName, uuid, masterID, offerMessage);
        name = productName;
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var isFromMiniCart = $(this).parents('.is-from-minicart').length > 0;
        var productID = $(this).data('pid');
        var offer = $(this).data('offer');
        var masterProductID = e.target.dataset.mpid;
        var url = $(this).data('action');
        var schedulePage = $('.cart-page-schedule').data('schedule-page') || false;
        var beforeSchedulePage = $('.cart-page-scheduling').data('before-schedule-page') || false;
        var testPrepPage = $('.test-preparation-page').data('test-preparation-page') || false;
        var testPrepPdp = $('.testprep').data('test-prep-pdp') || false;
        var testPdp = $('.test-product-detail').data('test-pdp') || false;
        var productSet = $('.product-set-detail').data('product-set-pdp') || false;
        var recommendation = $('.cart-page-scheduling').data('test-preparation-page') || false;
        var reviewCartTestPrep = $('.review-cart-test-prep').data('review-test-prep') || false;
        var scheduleFinalPage = $('.cart-page-scheduling').data('review-your-cart-page') || false;
        var summaryPage = $('.score-recipients-main').find('.totals__summary').length > 0;
        var isMyAccountHomePage = $('.upcoming-orders').data('is-my-account-home-page') || false;
        var requiredTests = $('.requiredTestsMulti').length || $('.requiredTests').length;
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid,
            offer: offer
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('#loadingDiv').show();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.gtmRemoveFromCart) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.gtmRemoveFromCart[0]);
                }
                if (data.basket.items.length === 0 && !summaryPage && !requiredTests && !testPrepPdp && !testPdp && !productSet && !isMyAccountHomePage) {
                    $('.generic-modal').removeClass('d-none');
                    $('.generic-modal').modal('show');
                    $('.modal-title').html(data.basket.reviewYourCartMessages.noProductInYourCart);
                    $('.modal-description').html(data.basket.reviewYourCartMessages.noProductInYourCartText);
                    $('.modal-footer .btn-secondary').removeAttr('data-dismiss');
                    $('.modal-footer .btn-secondary').html(data.basket.reviewYourCartMessages.goToMyAccountText);
                    $('.modal-footer .btn-primary').html(data.basket.reviewYourCartMessages.continueShoppingText);
                    $('.modal-footer .btn-primary').attr('href', data.basket.storeHomeURL);
                    $('.modal-footer .btn-secondary').attr('href', data.basket.myAccountURL);
                    $('.modal-footer .btn-secondary').removeClass('d-none');
                    $('.modal-header .close').attr('href', data.basket.homeURL);
                    $('.modal-header .close').removeAttr('data-dismiss');
                    $('.generic-modal').on('hidden.bs.modal', () => { window.location.href = data.basket.homeURL; });
                    $('.minicart-quantity').removeClass('d-block').addClass('d-none');
                    $('#loadingDiv').hide();

                    if (!isFromMiniCart) {
                        return;
                    }
                } else if (data.basket.containsOnlyTestPrep && !data.basket.anonymous && scheduleFinalPage) {
                    $('.generic-modal').removeClass('d-none');
                    $('.generic-modal').modal('show');
                    $('.modal-title').html(data.basket.reviewYourCartMessages.checkoutForOtherProducts);
                    $('.modal-description').html(data.basket.reviewYourCartMessages.checkoutForOtherProductsText);
                    $('.modal-footer .btn-primary').html(data.basket.reviewYourCartMessages.okText);
                    $('.modal-footer .btn-primary').attr('href', data.basket.testPrepCheckoutURL);
                    $('.modal-footer .btn-secondary').addClass('d-none');
                    $('.modal-header .close').attr('href', data.basket.testPrepCheckoutURL);
                    $('.modal-header .close').removeAttr('data-dismiss');
                    $('.generic-modal').on('hidden.bs.modal', () => { window.location.href = data.basket.testPrepCheckoutURL; });
                } else if (schedulePage) {
                    location.reload();
                    return;
                } else if (beforeSchedulePage || testPrepPage || scheduleFinalPage || reviewCartTestPrep || isMyAccountHomePage) {
                    if (reviewCartTestPrep && name.indexOf('Praxis Learning Paths') !== -1 && $('.totals .card').length > 3) {
                        location.reload();
                    } else {
                        updateUIAfterRemove(data, uuid);
                    }
                }

                let orderReviewElement = $('body').find(".line-product[data-pid='" + productID + "']");
                let btnOrderReviewElement = $('body').find(".addCartButtonResults[data-pid='" + productID + "']");
                let label = btnOrderReviewElement.data('addtocart');

                if (isFromMiniCart) {
                    updateCartTotals(data.basket);
                    $('.minicart-quantity--total').text(data.basket.numItems);
                    $(`.addCartButtonResults[data-pid="${productID}"]`).removeClass('adding');
                    if (data.basket.numItems === 0) {
                        $('.minicart-quantity').removeClass('d-block').addClass('d-none');
                    }
                    $('.cart-delete-confirmation-btn').removeAttr('data-pid');
                    var addToCartBTN = $('.add-to-cart').attr('data-pid');
                    var btnLabel = $('.add-to-cart').attr('data-addtocart');
                    var stringifiedPID = typeof (productID) === 'number' ? productID.toString() : productID;
                    var accountPage = $('.page').data('action') === 'Account-Show';
                    if (accountPage) {
                        var toRemove = Object.keys(data.toBeDeletedProductsUUIDS);
                        toRemove.forEach(item => {
                            var testPrep = $(`.btn-test-prep[data-uuid="${data.toBeDeletedProductsUUIDS[item]}"]`);
                            if (testPrep.hasClass('adding')) {
                                testPrep.removeClass('adding').removeAttr('disabled', 'disabled').html('Add to Cart');
                            }
                        });
                    }

                    var productSetPage = $('.productSets-setItems');

                    base.updateBundleAddToCartCTA(data);

                    if (productSetPage.length > 0) {
                        var addToCartBTNProductSet = $('.add-to-cart[data-pid="' + masterProductID + '"]').attr('data-pid');
                        var btnLabelProductSet = $('.add-to-cart').attr('data-addtocart');
                        var itensToRemove = data.toBeDeletedProductsUUIDS;

                        if (addToCartBTNProductSet === stringifiedPID || addToCartBTNProductSet === String(masterProductID)) {
                            $('.add-to-cart[data-pid="' + masterProductID + '"]').removeClass('added').attr('disabled', false).text(btnLabelProductSet);
                        }

                        let hasRemovedItemFromSet = false;
                        itensToRemove.forEach(function (item) {
                            let selectedElement = $('.add-to-cart[data-uuid="' + item + '"]');
                            if (selectedElement.length !== -1 && selectedElement.hasClass('added')) {
                                selectedElement.removeClass('added').attr('disabled', false).text(btnLabelProductSet);
                                hasRemovedItemFromSet = true;
                            }
                        });

                        if (hasRemovedItemFromSet) {
                            $('.add-to-cart-global').removeClass('added').attr('disabled', false).text(btnLabelProductSet);
                        }
                    } else if (addToCartBTN === stringifiedPID || addToCartBTN === masterProductID) {
                        $('.add-to-cart').removeClass('added').attr('disabled', false);
                        $('.add-to-cart').find('.label').text(btnLabel);
                    }

                    var addToCartBTNPrepPage = $('.addCartButtonResults').attr('data-pid');
                    var btnLabelPrepPage = $('.addCartButtonResults').attr('data-addtocart');

                    if (addToCartBTNPrepPage === stringifiedPID || addToCartBTNPrepPage === String(masterProductID)) {
                        $('.addCartButtonResults[data-pid="' + masterProductID + '"]').removeClass('added adding').attr('disabled', false).text(btnLabelPrepPage);
                    }

                    var addToCartBTNRecommendation = $('.add-to-cart-recommendation[data-pid="' + masterProductID + '"]').attr('data-pid');
                    var btnLabelRecommendation = $('.add-to-cart-recommendation').attr('data-addtocart');

                    if (addToCartBTNRecommendation === stringifiedPID || addToCartBTNRecommendation === String(masterProductID)) {
                        $('.add-to-cart-recommendation[data-pid="' + masterProductID + '"]').removeClass('added').attr('disabled', false).text(btnLabelRecommendation);
                    }

                    if (document.querySelector('.add-test.added[data-pid="' + masterProductID + '"]') !== null) {
                        let addedBtn = document.querySelectorAll('.add-test.added[data-pid="' + masterProductID + '"]');
                        [...addedBtn].forEach(btnElement => {
                            btnElement.classList.add('d-none');
                            btnElement.previousElementSibling.classList.remove('d-none');
                        });
                        $('.link--view-register').removeClass('d-none added').removeAttr('disabled');
                        $(`.add[data-pid="${productID}"]`).removeClass('d-none add').siblings('.added').addClass('d-none');
                        updateParentClass();
                    }

                    if ($('.cta-add-product').length) {
                        for (let index = 0; index < $('.cta-add-product').length; index++) {
                            var pids = $('.cta-add-product').eq(index).data('pid').toString()
                                .split(' ');
                            var hasPid = pids.includes(masterProductID);
                            if (hasPid) {
                                $('.cta-add-product').eq(index).removeClass('added');
                            }
                        }
                    }

                    if ($('.add-to-cart-bundle[data-pid="' + productID + '"]').length) {
                        $('.add-to-cart-bundle[data-pid="' + productID + '"]').removeClass('added').attr('disabled', false).text(btnLabelRecommendation);
                    }
                } else {
                    updateUIAfterRemove(data, uuid);
                }
                if (orderReviewElement && orderReviewElement.length !== -1) {
                    orderReviewElement.closest('.text-right').remove();
                    btnOrderReviewElement.closest('.carousel-item').length ? btnOrderReviewElement.html(label + '<span class="icon icon--add"></span>').attr('disabled', false) : btnOrderReviewElement.html(label).attr('disabled', false);// eslint-disable-line
                    recommendation ? btnOrderReviewElement.removeClass('adding') : '';// eslint-disable-line
                }

                updateMinicart();
                updateCartTotals(data.basket);
                updateProductItems(data.basket.items);

                data.basket.items.forEach(item => {
                    if (item.relatedRecommendation) {
                        updateRelatedItems(item.relatedRecommendation);
                    }
                });

                if (summaryPage) {
                    window.location.href = data.basket.homeURL;
                }

                const carousel = $('.cart-page-recommendations').find('.carousel');
                const cartRecommendedProductSection = $('.cart-page-recommendations');
                if (carousel && isRecommendedProductExists(carousel) && cartRecommendedProductSection) {
                    cartRecommendedProductSection.removeClass('d-none');
                } else {
                    cartRecommendedProductSection.addClass('d-none');
                }

                $('body').trigger('cart:update', data);

                $('#loadingDiv').hide();
            },
            error: function (err) {
                var globalResources = window.globalResources;
                if (globalResources) {
                    globalResources = globalResources.replace(/&quot;/g, '"');
                    globalResources = JSON.parse(globalResources);
                }
                $('.generic-modal').removeClass('d-none');
                $('.generic-modal').modal('show');
                $('.modal-title').html(globalResources.somethingWrongMessage);
                $('.modal-description').html(globalResources.tryAgainMessage);
                $('.modal-footer .btn-primary').html(globalResources.okText);
                $('.modal-footer .btn-secondary').html('');
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $('#loadingDiv').hide();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        const alertImage = e.target.dataset.alertImage;
        $('#loadingDiv').show();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('#loadingDiv').hide();
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.coupons-and-promos .coupon-code').remove();
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(`<img class="coupon-error--image" src="${alertImage}" alt="Error" width="14" height="14" loading="lazy"/>${data.errorMessage}`);
                    $('body').trigger('promotion:error', data);
                    $('.promo-code-btn').removeClass('promo-code-typing');
                    $('.promo-code-btn').removeClass('promo-code-valid');
                    $('.promo-code-btn').addClass('promo-code-invalid');
                    $('.coupon-code-section').find('.icon--close').remove();
                    $('.coupon-code-field').after('<span class="icon icon--close"></span>');
                    $('.coupon-code-section .icon--close').click(function () {
                        $(this).prev('input.coupon-code-field').val('');
                        $(this).hide();
                        $('.promo-code-form .form-control').removeClass('is-invalid');
                        $('.coupon-error-message').empty();
                        $('.promo-code-btn').removeClass('promo-code-invalid');
                    });
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                    $('.promo-code-btn').removeClass('promo-code-typing');
                    $('.promo-code-btn').removeClass('promo-code-invalid');
                    $('.promo-code-btn').addClass('promo-code-valid');
                    $('.coupon-error-success').empty().append(data.sucessMessage);
                    $('.coupon-code-section .icon--close').hide();
                }
                $('#loadingDiv').hide();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $('#loadingDiv').hide();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var url = $(this).data('action');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('#loadingDiv').show();
        $('body').trigger('promotion:beforeUpdate');
        $('.coupon-code-field').val('');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                $('.promo-code-btn').addClass('promo-code-typing');
                $('.promo-code-btn').removeClass('promo-code-invalid', 'promo-code-valid');
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $('#loadingDiv').hide();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $('#loadingDiv').hide();
                }
            }
        });
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};

module.exports.updateCartTotals = updateCartTotals;
module.exports.updateApproachingDiscounts = updateApproachingDiscounts;
module.exports.validateBasket = validateBasket;
module.exports.updateAvailability = updateAvailability;
module.exports.updateProductDetails = updateProductDetails;
