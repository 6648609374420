'use strict';

var recoType = 'list';
if ($('.storepage').data('reco')) {
    recoType = $('.storepage').data('reco');
}
/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Opens a popup with recommendations products
 * @param {string} response - ajax response from clicking the add to cart button
 */
function recommendedPostCartAdd(response) {
    if (response.error) return;
    const modalHTML = `<div class="recommendation__modal ${recoType}">
        <div class="modal fade" id="recommendationsModal" tabindex="-1" role="dialog">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog choose-bonus-product-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <span></span>
                    </div>
                    <button type="button" class="close pull-right" data-dismiss="modal"></button>
                    <div class="modal-body"></div>
                    <button class="close button" data-dismiss="modal">No, Thanks.</button>
                </div>
            </div>
        </div>
    </div>`;

    const recommendedItemHTML = (recommendation, { addToCartLabel, addedCartLabel, addToCartUrl }) => {
        return `<div class="recommended-card">
                <div class="recommended-card--image">
                    <img src="${recommendation.cardImage}" alt="${recommendation.productName}" loading="lazy" width="100" height="100"/>
                </div>
                <div class="recommended-card--details">
                    <div class="recommended-card--title">
                        <a href="${recommendation.URL}">
                            ${recommendation.productName}
                        </a>
                        <p>${recommendation.recommendClaim}</p>
                    </div>

                    <div class="recommended-card--actions">
                        <div class="recommended-card--price">
                            ${(recommendation.price.list && recommendation.price.list.formatted) || (recommendation.price.sales && recommendation.price.sales.formatted)}
                        </div>
                        <button class="add-test btn btn-primary ${recommendation.inCart ? 'added' : 'add'}"
                            data-pid="${recommendation.id}"
                            data-add-cart-url="${addToCartUrl}"
                            data-added-label="${addedCartLabel}"
                            data-add-label="${addToCartLabel}"
                            data-is-plp="${recommendation.isPLP}"
                            ${recommendation.inCart ? 'disabled' : ''}>
                            ${recommendation.inCart ? addedCartLabel : addToCartLabel }
                        </button>
                    </div>
                </div>
        </div>`;
    };

    const recommendedItemCarousel = (recommendation, { addToCartLabel, addedCartLabel, addToCartUrl }, template, index) => {
        return `<div data-position="${index}" class='carousel-item'><div class="card product-info testPrep ${template.name ? template.name : 'ipt'}">
        <div class="line-item-left col-lg-5">
            <img src="${recommendation.cardImage}" alt="${recommendation.productName}" loading="lazy" width="100" height="100"/>
            <span class="line-item--name">${recommendation.productName}</span>
            <p class="line-item--price">${(recommendation.price.list && recommendation.price.list.formatted) || (recommendation.price.sales && recommendation.price.sales.formatted)}</p>
        </div>
        <div class="line-item-right col-lg-7">
            <div class="graphic">${template.graphic}</div>
            <div class="image">${template.image}</div>
            <div class="line-item-hover">
                <div class="graphic">${template.graphicHover}</div>
                <span class="hover--title">Included Benefits:</span>
                ${template.benefits}
            </div>
        </div>
        <div class="line-item-bottom col-12">
            <button class="reco-details open">
                Show Details
                <span class="icon icon--arrow-down"></span>
            </button>
            <div class="test-preparation-btn">
                <input type="hidden" class="add-to-cart-url" value="${addToCartUrl}">
                <input type="hidden" class="quantity-select" name="qty" value="1">

                <button class="add-test btn btn-primary ${recommendation.inCart ? 'added' : 'add'}"
                            data-pid="${recommendation.id}"
                            data-add-cart-url="${addToCartUrl}"
                            data-added-label="${addedCartLabel}"
                            data-add-label="${addToCartLabel}"
                            data-is-plp="${recommendation.isPLP}"
                            ${recommendation.inCart ? 'disabled' : ''}>
                            ${recommendation.inCart ? addedCartLabel : addToCartLabel + '<span class="icon icon--add"></span>' }
                        </button>
            </div>
        </div>
    </div></div>`;
    };

    fetch(response.getRecommendationsURL)
        .then(res => res.json())
        .then(data => {
            if (data.recommendations.length) {
                $('body').append(modalHTML);
                const modalRecommendations = $('#recommendationsModal')[0];
                modalRecommendations.querySelector('.modal-header span').innerText = data.headerLabel;
                var plpImage = $('#plpImage');

                if (!data.isPLP && plpImage.length) {
                    plpImage.remove();
                }

                if (data.isPLP && data.plpPromotionImage && !plpImage.length) {
                    const imageElement = document.createElement('img');
                    imageElement.id = 'plpImage';
                    imageElement.src = data.plpPromotionImage;

                    const headerElement = modalRecommendations.querySelector('.modal-header');
                    headerElement.classList.add('special-offer-header');

                    const spanElement = modalRecommendations.querySelector('.modal-header span');
                    spanElement.classList.add('special-offer-span');
                    headerElement.insertBefore(imageElement, spanElement);
                }

                modalRecommendations.querySelector('.modal-body').innerHTML = '';
                let htmlItems = '';
                let htmlCards = '';
                data.recommendations.forEach((recommendation, index) => {
                    var testPrepType = recommendation.testPrepType ? recommendation.testPrepType : 'ipt';
                    var typeTemplate = data.cardsTemplateData.find(template => { return template.name === testPrepType; });

                    htmlItems += recommendedItemHTML(recommendation, data);
                    htmlCards += recommendedItemCarousel(recommendation, data, typeTemplate, index);
                });
                let carousel = `<div class="carousel"><div class='testprepPromo-carousel'>${htmlCards}</div></div>`;
                if (recoType === 'list') {
                    modalRecommendations.querySelector('.modal-body').innerHTML = htmlItems;
                } else {
                    modalRecommendations.querySelector('.modal-body').innerHTML = carousel;
                }
                $('.testprepPromo-carousel').slick({
                    dots: true,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                dots: false,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                arrows: false,
                                variableWidth: true,
                                swipe: false,
                                touchMove: false
                            }
                        },
                        {
                            breakpoint: 769,
                            settings: {
                                dots: false,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                arrows: false,
                                variableWidth: true,
                                swipe: false,
                                touchMove: false
                            }
                        }
                    ]
                });

                $('.recommendation__modal .reco-details').on('click', function () {
                    $(this).toggleClass('open');
                    var parent = $(this).closest('.card');
                    parent.toggleClass('open').find('.line-item-left, .line-item-right').toggleClass('active');
                });

                setTimeout(() => {
                    $(window).trigger('resize');
                }, 500);
                [...modalRecommendations.querySelectorAll('.add-test')].forEach(recommendedTest => {
                    recommendedTest.addEventListener('click', recommendedModalAddToCart); // eslint-disable-line
                });
                $(modalRecommendations).modal('show');

                if (data.closeModal) {
                    $(modalRecommendations).modal('hide');
                }
            }
        })
        .catch(error => {
            console.log(error);
            throw new Error('Error getting recommendations');
        });
}

/**
 * Add to cart recommended pop products event
 * @param {Object} event event click object;
 */
function recommendedModalAddToCart(event) {
    event.preventDefault();
    const target = event.target;
    const { pid, addCartUrl, isPlp } = target.dataset;
    const form = new FormData();
    let formData = {
        pid: pid,
        isPLP: isPlp,
        quantity: 1,
        gtmSource: 'staterequirement'
    };
    Object.keys(formData).forEach(key => {
        form.append(key, formData[key]);
    });

    if (addCartUrl) {
        fetch(addCartUrl, {
            method: 'POST',
            body: form
        })
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    target.classList.remove('add');
                    target.classList.add('added');
                    target.setAttribute('disabled', true);
                    target.innerText = target.dataset.addedLabel;
                    window.dataLayer = window.dataLayer || [];

                    for (let gtmAddToCart of data.gtmAddToCart) {
                        window.dataLayer.push(gtmAddToCart);
                    }

                    if (isPlp && window.preferences.StateRequirementesRecommendations) {
                        recommendedPostCartAdd(data); // eslint-disable-line
                    }

                    var bonus = data.newBonusDiscountLineItem.bonuspids;
                    var btnLabel = $('.add-to-cart').attr('data-added');

                    if (bonus && bonus.length > 0) {
                        bonus.forEach(function (item) {
                            $('.add-to-cart[data-pid="' + item + '"]').addClass('added').attr('disabled', true).text(btnLabel);
                        });

                        $('.add-to-cart-global').addClass('added').attr('disabled', true).text(btnLabel);
                    }

                    $('.minicart').trigger('count:update', data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('.add-to-cart-global').addClass('added').attr('disabled', true).text(btnLabel);
                    if (data.newBonusDiscountLineItem) {
                        var bounsItems = Object.keys(data.newBonusDiscountLineItem.bonuspids);
                        bounsItems.forEach(bonuspid => {
                            var testPrep = $(`.btn-test-prep[data-pid="${data.newBonusDiscountLineItem.bonuspids[bonuspid]}"]`);
                            if (!testPrep.hasClass('adding')) {
                                testPrep.addClass('adding').attr('data-added', 'added').attr('disabled', 'disabled').html('Added');
                            }
                        });
                    }
                }
            }).catch(() => {
                $.spinner().stop();
            });
    }
}

module.exports = {
    handlePostCartAdd,
    recommendedPostCartAdd,
    miniCartReportingUrl
};
