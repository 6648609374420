'use strict';

/**
 * Fetches institutions data.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} body - The request body.
 * @returns {Promise<Object>} - The fetched data.
 */
export async function fetchInstitutionsFormData(endpoint, body) {
    const response = await fetch(endpoint, {
        method: 'POST',
        body: body
    });
    const bodyInstitutions = await response.json();
    return bodyInstitutions;
}

/**
 * Fetches institutions data.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} body - The request body.
 * @returns {Promise<Object>} - The fetched data.
 */
export async function fetchInstitutions(endpoint, body) {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    const bodyInstitutions = await response.json();
    return bodyInstitutions;
}

/**
 * Fetches score acceptance data.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} body - The request body.
 * @returns {Promise<Object>} - The fetched data.
 */
export async function fetchScoreAcceptance(endpoint, body) {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    const bodyScore = await response.json();
    return bodyScore;
}

/**
 * Fetches score acceptance data.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} body - The request body.
 * @returns {Promise<Object>} - The fetched data.
 */
export async function fetchEditEPP(endpoint, body) {
    const params = new URLSearchParams(body).toString();
    const response = await fetch(endpoint + '?' + params, {
        method: 'GET'
    });
    const bodyScore = await response.json();
    return bodyScore;
}
