window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var $ = require('jquery');
require('jquery-validation');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('ets/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./CTAaddToCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/slider'));
    processInclude(require('./components/cart/global'));
    // TODO EPMS check how to import properly the file so the
    // add to cart call does not executes twice (one in ets cartridge and the other in nase)
    processInclude(require('./product/base'));
});

require('slick-carousel');
require('base/thirdParty/bootstrap');
require('base/components/spinner');
