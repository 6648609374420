
$('.productResources-carousel').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: '0px',
    initialSlide: 0,
    dots: true,
    prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="slick-next">Next</button>',
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                prevArrow: '',
                nextArrow: ''
            }
        }
    ]
});

let productRecoValue = $('.productRecommendations-carousel').attr('data-length');
let productRecoLength = Math.trunc(productRecoValue);

let carouselLength = 0;

if (productRecoLength < 3) {
    carouselLength = productRecoLength;
} else {
    carouselLength = 3;
}

$('.productRecommendations-carousel').slick({
    slidesToShow: carouselLength,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    centerPadding: '0px',
    initialSlide: 0,
    dots: true,
    prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="slick-next">Next</button>',
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                prevArrow: '',
                nextArrow: ''
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                prevArrow: '',
                nextArrow: ''
            }
        }
    ]
});

$('.bundle-carousel').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            }
        }
    ]
});

var carousel = $('.helpful-tools .helpful-tools__carousel');
var isMobile = $(window).width() < 680;
var helpfulToolsLength = $('.helpful-tools .helpful-tools__carousel').children().length;
if (isMobile && helpfulToolsLength >= 2) {
    carousel.slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });
} else if (!isMobile && helpfulToolsLength > 4) {
    carousel.slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: false
    });
}

$('.testprepPromo-carousel').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                variableWidth: true,
                swipe: false,
                touchMove: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                variableWidth: true,
                swipe: false,
                touchMove: false
            }
        }
    ]
});

$('.recommended-carousel').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                variableWidth: true
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: true
            }
        }
    ]
});
