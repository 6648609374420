'use strict';
const pageSize = $('.pagination-container').data('size');
let currentPage = 1;

/**
 * Fetches and updates a page of content based on the start index.
 * This function uses AJAX to request a new set of cards and updates the DOM with the new cards.
 * It also starts and stops a spinner to indicate loading.
 * @param {number} start - The starting index for the new set of cards to be fetched.
 */
function fetchPage(start) {
    let baseUrl = $('.more').data('url');

    baseUrl = baseUrl.slice(0, baseUrl.indexOf('&start'));

    const url = `${baseUrl}&start=${start}&sz=${pageSize}`;
    $.ajax({
        url: url,
        method: 'GET',
        success: function (response) {
            const newCards = $(response).find('.col-12');
            $('#card-container').html(newCards);
        }
    });
}

/**
 * Renders the pagination buttons based on the current page, total pages, and screen size.
 * This function uses global variables currentPage, totalPages, and pageSize.
 * @global
 */
function renderPagination() {
    let totalElements = $('.pagination-container').data('count');
    let totalPages = Math.ceil(totalElements / pageSize);
    $('#pagination').empty(); // Clear existing pagination buttons

    const windowWidth = window.innerWidth;

    // Determine the maximum number of pages to display
    let maxPagesToShow = 13; // default value for desktop
    if (windowWidth <= 600) {
        maxPagesToShow = 5; // default value for mobile
    }

    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(currentPage + Math.floor(maxPagesToShow / 2), totalPages);

    if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        endPage = Math.min(maxPagesToShow, totalPages);
    } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
        startPage = Math.max((totalPages - maxPagesToShow) + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        const startValue = (i - 1) * pageSize;
        const button = $('<button>')
            .text(i)
            .attr('data-start', startValue)
            .addClass('pagination-button')
            .toggleClass('active', i === currentPage);
        $('#pagination').append(button);
    }

    for (let i = 0; i < $('.dropdown-search--item').length; i++) {
        var dat = $(`.dropdown-search--item:nth-of-type(${i + 1})`).find('button');
        dat = dat.attr('data-href', `${dat.data('href')}&start=0&sz=${pageSize}`);
    }

    // Add '...' buttons if needed
    if (startPage > 1) {
        $('#pagination').prepend($('<button>').text('...').addClass('jump-to-start'));
    }
    if (endPage < totalPages) {
        $('#pagination').append($('<button>').text('...').addClass('jump-to-end'));
    }
}

/**
 * Updates the range of results currently displayed on the page.
 * This function uses global variables currentPage, pageSize, and totalElements.
  * @param {any} defaultCurrent - set the first page when a filter is selected
 */
function updateResultRange(defaultCurrent) {
    let totalElements = $('.pagination-container').data('count');
    if (defaultCurrent) {
        currentPage = defaultCurrent;
    }
    let startRange = ((currentPage - 1) * pageSize) + 1;
    let endRange = currentPage * pageSize;

    if (endRange > totalElements) {
        endRange = totalElements;
    }

    $('#result-range').text(`${startRange}-${endRange} of ${totalElements} results`);
}

$('.container').on('click', '#pagination .pagination-button', function () {
    const start = $(this).attr('data-start');
    currentPage = parseInt($(this).text(), 10); // Refresh the current page
    fetchPage(start);
    renderPagination();
    updateResultRange();
    $(window).scrollTop(0);
});

$('.container').on('click', '#pagination .jump-to-start', function () {
    currentPage = 1;
    const start = ((currentPage - 1) * pageSize);
    fetchPage(start);
    renderPagination();
    updateResultRange();
    $(window).scrollTop(0);
});

$('.container').on('click', '#pagination .jump-to-end', function () {
    let totalElements = $('.pagination-container').data('count');
    let totalPages = Math.ceil(totalElements / pageSize);
    currentPage = totalPages;
    const start = ((currentPage - 1) * pageSize);
    fetchPage(start);
    renderPagination();
    updateResultRange();
    $(window).scrollTop(0);
});

$('.container').on('click', '#prev-page', function () {
    if (currentPage > 1) {
        currentPage -= 1;
        const start = ((currentPage - 1) * pageSize);
        fetchPage(start);
        renderPagination();
        updateResultRange();
        $(window).scrollTop(0);
    }
});

$('.container').on('click', '#next-page', function () {
    let totalElements = $('.pagination-container').data('count');
    let totalPages = Math.ceil(totalElements / pageSize);
    if (currentPage < totalPages) {
        currentPage += 1;
        const start = ((currentPage - 1) * pageSize);
        fetchPage(start);
        renderPagination();
        updateResultRange();
        $(window).scrollTop(0);
    }
});

$(window).resize(function () {
    renderPagination();
});

// Initialize the pagination
renderPagination();
updateResultRange();

module.exports = {
    renderPagination: renderPagination,
    updateResultRange: updateResultRange
};
