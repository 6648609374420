'use strict';

import { fetchInstitutions, fetchInstitutionsFormData, fetchScoreAcceptance, fetchEditEPP } from './eppScorePostRequests.js';

var globalResources = window.globalResources;
globalResources = globalResources.replace(/&quot;/g, '"');
globalResources = JSON.parse(globalResources);

export const verifyAttributes = (attribute, comma) => {
    let newAttribute = attribute ?? '';
    return `${newAttribute ? (comma ? newAttribute + ',' : newAttribute) : ''}`; // eslint-disable-line no-nested-ternary
};

var eppStateOptionIsSelected = false;
const getTemplateOptions = (institutionsList, testProductId, type = 'radio') => {
    const isEPPTemplate = type === 'radio';
    let newTemplate = institutionsList.map(institution => {
        return ` <li class="state-option"
                id="${institution.institutionCode}"
                data-value="${institution.institutionCode}"
                data-name="${String(institution.organizationName).trim().replace(/\s\s+/g, ' ')}"
                data-address="${institution?.address?.address}, ${institution?.address?.city}, ${institution?.address?.state}, ${institution?.address?.zipCode}"
                tabindex="0" role="option">
                <label class="${isEPPTemplate ? 'epp-form-label' : 'free-score-form-label'}"
                    data-code="${institution.institutionCode}"
                    data-institution-name="${institution.organizationName}"
                    data-accept-score="${institution?.acceptScoreForCurrentTest ?? false}"
                >
                    <input
                        type="${type}"
                        name="${isEPPTemplate ? 'stateInstitution' : 'freeScoreStateInstitution'}"
                        value="${institution.institutionCode}"
                        data-product-id="${testProductId}"
                        data-institution-institutionCode="${institution.institutionCode}"
                        data-institution-organizationName="${institution.organizationName}"
                        data-institution-country="${institution.country}"
                        data-institution-stateCode="${institution.stateCode}"
                        data-institution-stateDescription="${institution.stateDescription}"
                        data-institution-organizationId="${institution.organizationId}"
                        data-institution-address="${institution?.address?.address ?? ''}"
                        data-institution-city="${institution?.address?.city ?? ''}"
                        data-institution-state="${institution?.address?.state ?? ''}"
                        data-institution-zipCode="${institution?.address?.zipCode ?? ''}"
                        />
                    <span class="${isEPPTemplate ? 'epp-form-radio' : 'free-score-form-radio'}"></span>
                    <div class="state-option--details">
                        <p class="state-option--name">
                            ${String(institution.organizationName).trim().replace(/\s\s+/g, ' ')}
                            ${String('(' + institution.institutionCode + ')').trim().replace(/\s\s+/g, ' ')}
                        </p>
                        <p class="state-option--address">
                            ${verifyAttributes(institution?.address?.address, true)}
                            ${verifyAttributes(institution?.address?.city, true)}
                            ${verifyAttributes(institution?.address?.state, true)}
                            ${verifyAttributes(institution?.address?.zipCode)}
                        </p>
                    </div>
                </label>
                ${isEPPTemplate ? '' : '<div class="accept-score d-none"></div>'}
            </li >`;
    });
    return newTemplate.join('');
};

const setStateInstitutionOptions = (container, institutionsList, testProductId, checkInstitutionURL, type = 'radio') => {
    container.innerHTML = ''; // eslint-disable-line no-param-reassign
    let searchInputValue = document.querySelector('[name="searchEPPByState"]').value;
    let templateStateInstitutions = `<p class="state-results">${institutionsList.length} ${container.dataset.results} <strong>&quot;${searchInputValue}&quot;</strong></p>
    <ul data-validate-institution-url="${checkInstitutionURL ?? ''}">
        ${getTemplateOptions(institutionsList, testProductId, type)}
    </ul>`;
    container.innerHTML = templateStateInstitutions; // eslint-disable-line no-param-reassign
    container.classList.remove('d-none');
};

const setDefaultFalseOption = () => {
    const addAsScoreRecipient = document.querySelectorAll('[name="addAsScoreRecipient"]');
    [...addAsScoreRecipient].forEach(e => {
        // eslint-disable-next-line no-param-reassign
        e.checked = e.value === 'false';
    });
};

export const toggleEppErrorMessage = (status) => {
    let errorElement = document.querySelector('.epp-search-state--error');
    status ? errorElement.classList.add('d-none') : errorElement.classList.remove('d-none');
    !status && setDefaultFalseOption();
};

export const enableModalSaveChanges = () => {
    const saveEpp = document.querySelector('.save-epp');
    const eppSelectedInput = document.getElementById('epp-select-id');
    const isSendScoreInputButtonDisabled = document.querySelector('input[name="eppSearchSendScores"][value="true"]').disabled;

    if ((eppSelectedInput && !eppSelectedInput.value) && (isSendScoreInputButtonDisabled && isSendScoreInputButtonDisabled === false)) {
        saveEpp?.classList.add('disabled');
        return;
    }

    if (eppStateOptionIsSelected) {
        saveEpp?.classList.remove('disabled');
        return;
    }

    const modalSelectedEpp = document.querySelector('.modal-epp-selected');
    const selectedEPPID = modalSelectedEpp && modalSelectedEpp.dataset.modalEppSelected;
    const organizationID = modalSelectedEpp && modalSelectedEpp.dataset.modalEppSelectedOrgId;
    const selectedModalProductID = modalSelectedEpp && modalSelectedEpp.dataset.modalProductId;
    const selectedModalBookingSeqNo = modalSelectedEpp && modalSelectedEpp.dataset.bookingSequenceNo;
    let eppModalSelector = document.querySelector('.select-group-filter:not(.d-none)');

    if (!selectedEPPID) {
        return;
    }

    if (eppModalSelector.id === 'eppStateFilter') {
        const stateInput = document.querySelector('input[name="searchEPPByState"]');
        stateInput.dataset.productId = selectedModalProductID;
        stateInput.dataset.bookingSequence = selectedModalBookingSeqNo;
        stateInput.dataset.eppId = selectedEPPID;
        stateInput.dataset.organizationId = organizationID;
    }

    saveEpp?.classList.remove('disabled');
};

export const toggleSendScores = (status) => {
    let sendScoresElement = document.querySelector('.epp-search-send-scores');
    if (sendScoresElement) {
        let inputScoresSendSelected = sendScoresElement.querySelector('[name="eppSearchSendScores"]:checked');
        !!inputScoresSendSelected && (inputScoresSendSelected.checked = false);

        if (!status) {
            sendScoresElement.querySelector('[name="eppSearchSendScores"][value="false"]').checked = true;
        } else {
            sendScoresElement.querySelector('[name="eppSearchSendScores"][value="true"]').checked = true;
        }
    }
};

const sendScoresEvents = () => {
    let sendScoresElement = document.querySelector('.epp-search-send-scores');
    let inputScoresSendSelected = sendScoresElement?.querySelectorAll('[name="eppSearchSendScores"]') || [];
    [...inputScoresSendSelected].forEach(scoresSendSelector => {
        scoresSendSelector.addEventListener('click', function () {
            enableModalSaveChanges();
        });
    });
};

export const getEppElementForSelectedProduct = (etsProductId) => { // eslint-disable-line
    const productCards = document.querySelectorAll('.product-info');
    var selectedProductElement;

    productCards && productCards.forEach((productCard) => {
        if (productCard.dataset.etsPid === etsProductId) {
            selectedProductElement = productCard;
        }
    });

    if (selectedProductElement) {
        var sendScoresElement = selectedProductElement.querySelector('.state-option--send-scores');

        return sendScoresElement;
    }
};

export const getElementForSelectedProduct = (etsProductId) => { // eslint-disable-line
    const productCards = document.querySelectorAll('.product-info');
    var selectedProductElement;

    productCards && productCards.forEach((productCard) => {
        if (productCard.dataset.etsPid === etsProductId) {
            selectedProductElement = productCard;
        }
    });

    return selectedProductElement;
};

const getSendScoreMsg = (isSavedAsScore, etsProductId) => { // eslint-disable-line
    const elementMsg = getEppElementForSelectedProduct(etsProductId);

    if (elementMsg) {
        elementMsg.classList.remove('text-red');

        return `<p>
            <strong>${elementMsg.dataset.label}</strong>
            ${isSavedAsScore !== false && isSavedAsScore !== 'false' ? elementMsg.dataset.true : elementMsg.dataset.false}
        </p>`;
    }
};

const addRemoveSelection = (inputField) => {
    const sectionField = inputField.parentElement;
    const removeBtn = sectionField.querySelector('.state-clean');

    if (removeBtn !== null) {
        removeBtn.remove();
    }

    const cleanerBtn = document.createElement('div');
    cleanerBtn.classList.add('state-clean');
    cleanerBtn.innerHTML = `
        <img src="${sectionField?.dataset.closeimg ?? ''}" alt="${sectionField?.dataset.closealt ?? ''}"/>
    `;
    cleanerBtn.addEventListener('click', e => {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        inputField.value = '';
        e.target.remove();
    });
    sectionField.append(cleanerBtn);
};

// eslint-disable-next-line consistent-return
export const freeScoreStatesSelection = async (element, testProductId, productId) => {
    const searchState = document.querySelector('.free-score-search-state-results');
    const selectable = element.dataset.selectable;
    if (selectable === 'false') {
        return false;
    }
    const code = element.dataset.code;
    const countryCode = element.dataset.countryCode;
    const stateCode = element.dataset.stateCode;
    const inputeField = element.closest('.form-custom-options')?.querySelector('[data-free-score-url]');
    const endpoint = inputeField.dataset.freeScoreUrl;
    const csrf = document.querySelector('.csrf');

    const scoreLoader = document.querySelector('.free-score-select-state-loader');
    searchState?.classList.add('d-none');
    scoreLoader?.classList.remove('d-none');

    const formData = new FormData();
    formData.append(csrf.name, csrf.value);
    formData.append('id', productId ?? null);
    formData.append('productId', testProductId ?? null);

    if (stateCode === 'null') {
        formData.append('type', 'state');
        formData.append('stateCode', '');
        formData.append('countryCode', countryCode);
    } else {
        formData.append('stateCode', stateCode);
        formData.append('countryCode', countryCode);
        formData.append('code', code);
    }

    const responseInstitutions = await fetchInstitutionsFormData(endpoint, formData);
    setStateInstitutionOptions(searchState, responseInstitutions.institutions, testProductId, responseInstitutions.checkInstitutionURL, 'checkbox');
    searchState.querySelector('.state-results strong').innerText = inputeField.value.trim();
    addRemoveSelection(inputeField);

    searchState?.classList.remove('d-none');
    scoreLoader?.classList.add('d-none');
};

export const freeScoreStatesSelectorEvents = (callback) => {
    document.querySelector('.free-score-search-state-results').addEventListener('click', e => {
        e.preventDefault();
        let target = e.target;
        let label = target.classList.contains('free-score-form-label') ? target.classList.contains('free-score-form-label') : target.closest('.free-score-form-label');

        if (label !== null) {
            let input = label.querySelector('input');
            input.checked = !input.checked;
            if (typeof callback === 'function') {
                callback(label);
            }
        }
    });
};

// eslint-disable-next-line consistent-return
export const statesSelection = async (element, testProductId) => {
    const eppSearchState = document.querySelector('.epp-search-state-results');
    const eppSelectLabelBackgroundInformation = document.querySelector('.guest-form .selected-epp-details');
    const selectable = element.dataset.selectable;
    if (selectable === 'false') {
        return false;
    }
    const code = element.dataset.code;
    const countryCode = element.dataset.countryCode;
    const stateCode = element.dataset.stateCode;
    const inputeField = element.closest('.form-custom-options')?.querySelector('[data-epp-url]');
    const endpoint = inputeField.dataset.eppUrl;
    const displayName = String(inputeField.value).trim();

    let body = {
        productId: testProductId ?? null,
        searchByState: true,
        search: {
            code,
            countryCode,
            stateCode,
            displayName
        }
    };

    if (stateCode === 'null') {
        body.search.country = true;
        delete body.search.stateCode;
        delete body.search.displayName;
    }

    const responseInstitutions = await fetchInstitutions(endpoint, body);
    setStateInstitutionOptions(eppSearchState, responseInstitutions.institutionsList, testProductId, responseInstitutions.checkInstitutionURL);

    addRemoveSelection(inputeField);

    eppSearchState?.classList.remove('d-none');
    eppSelectLabelBackgroundInformation?.classList.remove('d-none');

    let eppLoader = document.querySelector('.epp-select-state-loader');
    eppLoader?.classList.add('d-none');

    let eppStateResults = document.querySelector('.epp-search-state-results');
    eppStateResults?.classList.remove('d-none');

    let ctaEppSearch = document.querySelector('.btn-cta-epp');
    ctaEppSearch?.classList.remove('epp-cta-loading');

    let eppSendScores = document.querySelector('.epp-search-send-scores');
    eppSendScores?.classList.remove('d-none');
};

export const statesSelectorEvents = () => {
    const eppSearchState = document.querySelector('.epp-search-state-results');
    const stateInput = document.querySelector('input[name="searchEPPByState"]');
    const searchEPPInput = document.querySelector('input[name="searchEPP"]');

    eppSearchState?.addEventListener('click', async (e) => {
        e.preventDefault();
        if (e.target.closest('.state-option')) {
            const removeDecision = document.getElementById('removeDecision');
            const endpoint = eppSearchState.querySelector('ul').dataset.validateInstitutionUrl;
            const stateOption = e.target.closest('.state-option');
            const freeScoreRecipientRadioButton = document.querySelector('input[name="eppSearchSendScores"][value="true"]');
            const inputField = stateOption.querySelector('[name="stateInstitution"]');
            stateInput?.setAttribute('data-epp-id', inputField.dataset.institutionInstitutioncode);
            const eppSelectedInput = document.getElementById('epp-select-id');
            const eppSelectedName = document.getElementById('epp-select-name');
            const freeScoreRecipientsSaveButton = document.getElementById('SaveEppButton');
            eppSelectedInput !== null && (eppSelectedInput.value = stateOption.dataset.value);
            eppSelectedName !== null && (eppSelectedName.value = inputField.dataset.institutionOrganizationname);
            searchEPPInput !== null && (searchEPPInput.value = `${inputField.dataset.institutionOrganizationname} (${stateOption.dataset.value})`);
            freeScoreRecipientsSaveButton?.removeAttribute('disabled');
            freeScoreRecipientRadioButton?.removeAttribute('disabled');

            if (removeDecision) {
                removeDecision.value = false;
            }

            const institutionData = {
                institutionCode: inputField.dataset.institutionInstitutioncode,
                organizationName: inputField.dataset.institutionOrganizationname,
                country: inputField.dataset.institutionCountry,
                stateCode: inputField.dataset.institutionStatecode,
                stateDescription: inputField.dataset.institutionStatedescription,
                organizationId: Number(inputField.dataset.institutionOrganizationid),
                address: {
                    address: inputField.dataset.institutionAddress,
                    city: inputField.dataset.institutionCity,
                    state: inputField.dataset.institutionState,
                    zipCode: inputField.dataset.institutionZipcode
                }
            };

            const deleteEPPSelectedElement = $('.selected-epp-data');
            const deleteEPPIcon = $('.selected-epp-details .remove-epp');
            const defaultEPPMessage = $('.selected-epp-details--info__default');
            const selectedEPPContainer = $('.selected-epp-details');

            if (deleteEPPSelectedElement.length !== 0) {
                let selectedInstituteDetails = `${institutionData.organizationName ? institutionData.organizationName : ''} (${institutionData.institutionCode ? institutionData.institutionCode : ''}) ${institutionData.address.address ? institutionData.address.address : ''}${institutionData.address.city ? ', ' + institutionData.address.city : ''}${institutionData.address.state ? ', ' + institutionData.address.state : ''}${institutionData.address.zipCode ? ', ' + institutionData.address.zipCode : ''}`;
                deleteEPPSelectedElement[0].innerText = selectedInstituteDetails;
                deleteEPPIcon?.removeClass('d-none');
                defaultEPPMessage?.addClass('d-none');
                if (e.target.closest('.guest-form') && selectedEPPContainer.length > 0) {
                    selectedEPPContainer.removeClass('d-none');
                }
            }

            eppStateOptionIsSelected = true;

            inputField.checked = true;

            if (stateInput.dataset.updateProfile === undefined) {
                let scoreDataResponse = await fetchScoreAcceptance(endpoint, {
                    productID: inputField.dataset.productId,
                    institution: institutionData
                });

                let { acceptScoreForCurrentTest } = scoreDataResponse.nonAcceptanceData;

                const selectedProductElem = document.querySelector('.js-epp-selection-modal[data-id="' + inputField.dataset.productId + '"]');

                if (selectedProductElem) {
                    selectedProductElem.dataset.acceptAsSr = acceptScoreForCurrentTest;
                }

                const eppSelected = document.querySelector('.modal-epp-selected');

                if (eppSelected) {
                    eppSelected.dataset.modalEppSelected = inputField.dataset.institutionInstitutioncode;
                    eppSelected.dataset.modalEppSelectedOrgId = inputField.dataset.institutionOrganizationid;
                    eppSelected.dataset.modalProductId = inputField.dataset.productId;
                    eppSelected.dataset.bookingSequenceNo = selectedProductElem ? selectedProductElem.dataset.bookingsequenceno : '';
                    eppSelected.dataset.acceptScore = acceptScoreForCurrentTest;
                }

                toggleEppErrorMessage(acceptScoreForCurrentTest);
                toggleSendScores(acceptScoreForCurrentTest);
                enableModalSaveChanges();
            }
        }
    });

    sendScoresEvents();
};

export const resetSendScoreResponse = () => {
    let sendScoresElement = document.querySelector('.epp-search-send-scores');
    if (sendScoresElement) {
        var sendScoreButtons = sendScoresElement.querySelector('#send-epp-radio-buttons');
        if (sendScoreButtons) {
            sendScoreButtons.innerHTML = '';
            sendScoreButtons.innerHTML = `
                <label class="epp-form-label mr-5">
                    <input type="radio" name="eppSearchSendScores" value="true" class="send-scores" />
                    <span class="epp-form-radio"></span>
                    ${sendScoreButtons.dataset.yes}
                </label>
                <label class="epp-form-label">
                    <input type="radio" name="eppSearchSendScores" value="false" class="not-send-scores" />
                    <span class="epp-form-radio"></span>
                    ${sendScoreButtons.dataset.no}
                </label>
            `;
        }
    }
};

export const handleEppDeleteUI = (stateInput, reviewCartEppSendScores) => {
    var selectedEppElement = document.querySelector('.modal-epp-selected');
    var eppSelectedName = document.querySelector('.modal-epp-selected--epp-name');
    var eppSelectedAddress = document.querySelector('.modal-epp-selected--epp-address');

    if (eppSelectedName && eppSelectedAddress) {
        eppSelectedName.innerText = '';
        eppSelectedAddress.innerText = '';
    }

    if (selectedEppElement) {
        selectedEppElement.classList.add('d-none');
        selectedEppElement.dataset.modalEppSelected = '';
        selectedEppElement.dataset.modalEppSelectedOrgId = '';
        selectedEppElement.dataset.modalProductId = '';
        selectedEppElement.dataset.sendScoreValue = '';
        selectedEppElement.dataset.bookingSequenceNo = '';
        selectedEppElement.dataset.acceptScore = '';
    }

    var elementForSelectedProduct = getElementForSelectedProduct(stateInput.dataset.productId);
    var eppModalForSelectedProduct = elementForSelectedProduct.querySelector('.js-epp-selection-modal');

    if (eppModalForSelectedProduct) {
        const addEditEppLabel = eppModalForSelectedProduct.parentElement?.querySelector('.add-edit-epp');
        const addEditEppImage = eppModalForSelectedProduct.parentElement?.querySelector('.add-edit-epp-icon');
        eppModalForSelectedProduct.dataset.eppCode = '';
        eppModalForSelectedProduct.dataset.useAsSr = '';
        eppModalForSelectedProduct.dataset.value = '';
        eppModalForSelectedProduct.dataset.address = '';
        eppModalForSelectedProduct.dataset.acceptAsSr = '';
        eppModalForSelectedProduct.dataset.eppOrgId = '';
        eppModalForSelectedProduct.innerHTML = '';
        eppModalForSelectedProduct.innerHTML = globalResources.selectOne;
        if (addEditEppLabel !== null) {
            addEditEppLabel.innerText = addEditEppLabel.dataset.addLabel;
            addEditEppImage.src = addEditEppImage.dataset.addIcon;
        }
    }
    // eslint-disable-next-line no-param-reassign
    reviewCartEppSendScores.innerHTML = '';
};

export const saveStateEppSelected = async () => {
    const url = document.getElementById('eppStateFilter').dataset.editepp;
    const stateInput = document.querySelector('input[name="searchEPPByState"]');
    const reviewCartEpp = document.querySelector('.js-epp-selection-modal[data-id="' + stateInput.dataset.productId + '"]');
    const reviewCartEppSendScores = reviewCartEpp && reviewCartEpp.nextElementSibling;
    const $loadingElement = $('#loadingDiv');
    const removeEppIconElement = document.querySelector('#remove-epp-element');
    const removeNotification = document.querySelector('#remove-epp-icon-notification');
    const removeEppIconClickedElement = document.querySelector('#remove-epp-element-clicked');
    const removeDecision = document.getElementById('removeDecision');
    const eppSelected = document.querySelector('.modal-epp-selected');

    if (stateInput !== null) {
        const isSavedAsScoreRecipient = document.querySelector('[name="eppSearchSendScores"]:checked')?.value ?? false;
        let body = {
            productID: stateInput.dataset.productId,
            bookingSequenceNo: stateInput.dataset.bookingSequence,
            eppId: removeDecision && removeDecision.value === 'true' ? '' : eppSelected.dataset.modalEppSelected,
            addAsScoreRecipient: isSavedAsScoreRecipient,
            acceptSR: reviewCartEpp.dataset.acceptAsSr,
            removeDecision: removeDecision.value
        };
        if (reviewCartEpp !== null) {
            const institutionName = document.querySelector('.modal-epp-selected--epp-name').innerText;
            const institutionAddress = document.querySelector('.modal-epp-selected--epp-address').innerText;
            reviewCartEpp.classList.remove('text-red');
            reviewCartEpp.innerHTML = `<p class="state-option--name"> ${institutionName} </p> <p class="state-option--address"> ${institutionAddress}</p>`;
            reviewCartEppSendScores.classList.contains('state-option--send-scores') && (reviewCartEppSendScores.innerHTML = getSendScoreMsg(isSavedAsScoreRecipient, stateInput.dataset.productId));
        }
        let editEppResponse = await fetchEditEPP(url, body);
        if (!editEppResponse.success) {
            reviewCartEpp.innerHTML = reviewCartEpp.dataset.value;
        }

        removeNotification.classList.add('d-none');
        removeEppIconElement.classList.remove('d-none');
        removeEppIconClickedElement.classList.add('d-none');
        $loadingElement.hide();

        if (editEppResponse.success && !editEppResponse.eppDeleted) {
            var selectedEppElement = document.querySelector('.modal-epp-selected');

            if (selectedEppElement) {
                selectedEppElement.classList.remove('d-none');
                selectedEppElement.dataset.modalEppSelected = editEppResponse.organizationData?.institutionCode || '';
                selectedEppElement.dataset.modalEppSelectedOrgId = editEppResponse.organizationData?.organizationId || '';
                selectedEppElement.dataset.modalProductId = editEppResponse.productID || '';
                selectedEppElement.dataset.sendScoreValue = editEppResponse.sendScore || '';
                selectedEppElement.dataset.bookingSequenceNo = editEppResponse.bookingSequenceNo || '';
                selectedEppElement.dataset.acceptScore = editEppResponse.acceptSR || '';
            }

            var elementForSelectedProduct = getElementForSelectedProduct(stateInput.dataset.productId);
            var eppModalForSelectedProduct = elementForSelectedProduct.querySelector('.js-epp-selection-modal');
            var eppSelectedName = document.querySelector('.modal-epp-selected--epp-name');
            var eppSelectedAddress = document.querySelector('.modal-epp-selected--epp-address');
            var organizationAddress = `${editEppResponse.organizationData?.address?.address}, ${editEppResponse.organizationData?.address?.city}, ${editEppResponse.organizationData?.address?.state}, ${editEppResponse.organizationData?.address?.zipCode}`;
            var institutionNameAndCode = `${String(editEppResponse.organizationData.organizationName).trim().replace(/\s\s+/g, ' ')} ${String('(' + editEppResponse.organizationData.institutionCode + ')').trim().replace(/\s\s+/g, ' ')}`;

            eppSelectedName.innerText = institutionNameAndCode;
            eppSelectedAddress.innerText = organizationAddress;

            if (eppModalForSelectedProduct) {
                eppModalForSelectedProduct.dataset.eppCode = editEppResponse.organizationData?.institutionCode;
                eppModalForSelectedProduct.dataset.useAsSr = editEppResponse.sendScore;
                eppModalForSelectedProduct.dataset.value = institutionNameAndCode;
                eppModalForSelectedProduct.dataset.acceptAsSr = editEppResponse.acceptSR;
                eppModalForSelectedProduct.dataset.address = organizationAddress;
                eppModalForSelectedProduct.innerHTML = '';
                eppModalForSelectedProduct.innerHTML = `<p class="state-option--name">
                            ${institutionNameAndCode}
                        </p>
                        <p class="state-option--address">
                            ${organizationAddress}
                        </p>
                    </div>`;
            }
        }

        if (editEppResponse.eppDeleted) {
            handleEppDeleteUI(stateInput, reviewCartEppSendScores);
        }
    }

    $loadingElement.hide();
    removeNotification.classList.add('d-none');
    removeEppIconElement.classList.remove('d-none');
    removeEppIconClickedElement.classList.add('d-none');
    removeDecision.value = false;
};

let sendScoresElements = document.querySelector('#send-epp-radio-buttons');

sendScoresElements?.addEventListener('click', function () {
    enableModalSaveChanges();
});

